jQuery(document).ready(function($){

	//
	// ** Stop link from working on the language section
	//
	$(function(){
		$("aside.nav-sidebar .menu-languages-container .menu-item-has-children.wpml-ls-current-language > a").click(function(evt){
			evt.preventDefault();
		});
	});

	//
	// ** Video banner popup (open/close)
	//
	$(function(){
		$(".banner .play-button").click(function(){
			$(".banner .video-popup").fadeIn();
			$("video").each(function(){
				$(this).get(0).play();
			});
		});

		$(".banner .video-popup .video-close").click(function(){
			$(".banner .video-popup").fadeOut();
			$('iframe').attr('src', $('iframe').attr('src'));
			$("video").each(function(){
				$(this).get(0).pause();
			});
		});

		$(document).keyup(function(e) {
		     if( e.keyCode == 27 && $(".banner .video-popup").is(":visible") ) { // escape key maps to keycode `27`
		        $(".banner .video-popup").fadeOut();
		    }
		});
	});

	//
	// ** Open Main Navigation Function
	//
	$(function(){
		var $wrap = $(".ci-wrapper, .site-footer");
		var $nav  = $(".ci-navigation-menu");
		var $trig = $(".ci-menu");
		var $top;

		$trig.click(function(evt){
			evt.preventDefault();
			var $top = $(window).scrollTop();

			if( $("body").hasClass("nav-open") ){
				enableScroll();
				$wrap.toggleClass("active");
				$nav.toggleClass("active");
				$(".ci-menu").toggleClass("active");
				$(window).scrollTop($top);
			} else {

				$wrap.toggleClass("active");
				$nav.toggleClass("active");
				$(".ci-menu").toggleClass("active");
				disableScroll();
			}
			$("body").toggleClass("nav-open");
			$(window).scrollTop($top);
		});

		// left: 37, up: 38, right: 39, down: 40,
		// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
		var keys = {37: 1, 38: 1, 39: 1, 40: 1};

		function preventDefault(e) {
		  e = e || window.event;
		  if (e.preventDefault)
		      e.preventDefault();
		  e.returnValue = false;
		}

		function preventDefaultForScrollKeys(e) {
		    if (keys[e.keyCode]) {
		        preventDefault(e);
		        return false;
		    }
		}

		function disableScroll() {
		  if (window.addEventListener) // older FF
		      window.addEventListener('DOMMouseScroll', preventDefault, false);
		  window.onwheel = preventDefault; // modern standard
		  window.onmousewheel = document.onmousewheel = preventDefault; // older browsers, IE
		  window.ontouchmove  = preventDefault; // mobile
		  document.onkeydown  = preventDefaultForScrollKeys;
		}

		function enableScroll() {
		    if (window.removeEventListener)
		        window.removeEventListener('DOMMouseScroll', preventDefault, false);
		    window.onmousewheel = document.onmousewheel = null;
		    window.onwheel = null;
		    window.ontouchmove = null;
		    document.onkeydown = null;
		}
	});


	//
	// ** Address Accordion Function
	//
	$(function(){
		$(".ci-addresses .address:first").addClass("active"); // Initialise the first address box to be open

		$(".ci-addresses .address .address-heading").click(function(){
			$(".ci-addresses .address").removeClass("active");
			$(this).parent().toggleClass("active");
		});
	});

	//
	// ** .ci-text-slider-block columns height initialisation
	//
	$(function(){
		if( $(".ci-text-slider-block").length > 0 ){
			var $tsHeight = 0;

			if( $(window).width() > 650 ){
				$(".ci-text-slider-block > div").each(function(){
					if( $(this).outerHeight() > $tsHeight ){
						$tsHeight = $(this).outerHeight();
					}
				});
				$(".ts-slider-container li").css("height", $tsHeight + "px");
			}

			$(window).resize(function(){
				var maxHeight = parseInt(jQuery(".ts-slider-container li").css("min-height"));

				if( $(window).width() > 650 ){
					$(".ci-text-slider-block > div").each(function(){
						if( $(this).outerHeight() > $tsHeight ){
							$tsHeight = $(this).outerHeight();
						}
					});
					$(".ts-slider-container li").css("height", $tsHeight + "px");
				}
			});
		}
	});

	//
	// ** Banner Page Scroller / Remove scroller when not visible or clicked on
	//
	$(function(){
		$(".page-scroller i, .page-scroller svg").click(function(){
			$("html, body").animate({
				scrollTop: $(".banner").outerHeight() - $(".site-header").height(),
				top: 20
			}, 750);
			$(this).fadeOut();
		});

		// Remove when scrolled past
		$(window).scroll(function(){
			if( $(window).scrollTop() > $(".banner").outerHeight() ){
				$(".page-scroller i, .page-scroller svg").fadeOut();
			}
		});
	});

	//
	// ** Initialise bxSliders()
	//
	$(function(){ // News & Blog section on homepage
		var vpWidth = $(window).width() * 0.5;
		if( $(window).width() <= 800 ){
			vpWidth = $(window).width() * 0.7;
		}

		$(".article-slider").bxSlider({
			maxSlides: '3',
			moveSlides: '1',
			slideWidth: vpWidth,
			slideMargin: 50,
			pager: true,
			controls: false,
			useCSS: false,
			onSliderLoad: function(currentIndex) {
              $('.bw-wrapper').find('.bx-viewport').find('ul').children().eq(currentIndex + 1).addClass('active-slide');
            },
            onSlideBefore: function($slideElement){
              $('.feature-article').removeClass('active-slide');
              $slideElement.addClass('active-slide');
              if( $(".feature-article:not(.bx-clone):last").hasClass("active-slide") ){
			  	$(".feature-article.bx-clone:nth-child(4n)").attr("aria-hidden", 'false');
			  } else {
				  $(".feature-article.bx-clone:nth-child(4n)").attr("aria-hidden", 'true');
			  }
            }
		});

		// Change visual active state (opacity white) on last slide. - Last slide is a clone
		$(".feature-article:not(.bx-clone):first").addClass("active-slide");
		
		$('.lightbox-bxslider').bxSlider({
			controls: true,
			nextText: "<i class='fa fa-carat-right'></i>",
			nextText: "<i class='fa fa-carat-left'></i>",
		});
	});
	$(function(){
		$(".ts-slider").bxSlider({
			pager: true,
			mode: 'fade',
			controls: false,
			auto: true,
			autoStart: true
		});
	});
	$('.ts-slide').click(function() {
      var items = [];
      $('.ts-slide', $(this).closest('.ci-text-slider-block')).each(function() {
        console.log($(this));
        items.push({
          h: parseInt((that = $(this)).attr('data-h')),
          src: that.attr('data-full'),
          w: parseInt(that.attr('data-w'))
        });
      });
      var pswp = new PhotoSwipe($('.pswp')[0], PhotoSwipeUI_Default, items, {
        history: false, 
        index: parseInt($(this).attr('data-index'))
      });
      pswp.init();
      return false;
    });

	//
	// ** Header scroll function
	//
	$(function(){
		$(window).scroll(function(){
			if( $(window).scrollTop() >= ($(".banner").outerHeight() - $(".site-header").outerHeight() - ($(".site-header").outerHeight() / 2)) ){
				$(".site-header").addClass("scroll");
			} else {
				$(".site-header").removeClass("scroll");
			}
		});

		// Repeat for initial page load //
		if( $(window).scrollTop() >= ($(".banner").outerHeight() - $(".site-header").outerHeight() - ($(".site-header").outerHeight() / 2)) ){
			$(".site-header").addClass("scroll");
		} else {
			$(".site-header").removeClass("scroll");
		}
	});

	//
	// ** Timeline Line Follow screen function
	//
	function followTimeline(){
		if( $(window).width() > 500 ){
			$point = $(".ci-timeline-block .timeline .timeline-point").outerHeight() / 2 - 14; // - 14 because of the icon height.
			timeline = $(".ci-timeline-block .timeline").height() - $point;
			$current  = $(window).scrollTop();
			$currentH  = $(window).height() / 2;
			$line     = $(".ci-timeline-block .timeline .line");
			$linePos  = $line.offset().top;
			$lineCnt  = 0;
			$lineSum  = $current + $currentH - $linePos;
			$($line).css("max-height", timeline + "px");
			$("head").append("<style>.ci-timeline-block .timeline:before {max-height: " + timeline + "px;}</style>");

			if( $current + $currentH >= $linePos ){
				if( $lineSum <= $($line).height() ){
					// nothing
				} else {
					$($line).css("height", $lineSum + "px");
					$lineCnt++;
				}
			}

			$(".timeline-icon").each(function(){
				if( ($current + $currentH) > $(this).offset().top ){
					$(this).addClass("active");
				}
			});

			if( $line.height() > timeline ){
				$line.css("height", timeline + "px");
			}
		} else {
			$point = $(".ci-timeline-block .timeline .timeline-point").outerHeight() - 14; // - 14 because of the icon height.
			timeline = $(".ci-timeline-block .timeline").height() - $point;
			$current  = $(window).scrollTop();
			$currentH  = $(window).height() / 2 + ($(window).height() / 3);
			$line     = $(".ci-timeline-block .timeline .line");
			$linePos  = $line.offset().top;
			$lineCnt  = 0;
			$lineSum  = $current + $currentH - $linePos;
			$($line).css("max-height", timeline + "px");
			$("head").append("<style>.ci-timeline-block .timeline:before {max-height: " + timeline + "px;}</style>");

			if( $current + $currentH >= $linePos ){
				if( $lineSum <= $($line).height() ){
					// nothing
				} else {
					$($line).css("height", $lineSum + "px");
					$lineCnt++;
				}
			}

			$(".timeline-icon").each(function(){
				if( ($current + $currentH) > $(this).offset().top ){
					$(this).addClass("active");
				}
			});

			if( $line.height() > timeline ){
				$line.css("height", timeline + "px");
			}
		}
	}
	$(function(){
		if( $(".ci-timeline-block").length > 0 ){


			$(window).scroll(function(event){
				followTimeline();
			});

			$(".timeline-icon").click(function(){
				if( $(window).width() >= 800 ){
					$("html, body").animate({
						scrollTop: $(this).parent().offset().top - $(this).parent().height() - $(".site-header").height() - 30
					});
				}
				$(window).resize(function(){
					if( $(window).width() >= 800 ){
						$("html, body").animate({
							scrollTop: $(this).parent().offset().top - $(this).parent().height() - $(".site-header").height() - 30
						});
					}
				});
			});
		}
	});

	//
	// ** AJAX change Case Studies category
	//
/*
	$(function(){
		$(".filter-option").click(function(evt){
			evt.preventDefault();
			var $URL = $(this).attr("src");
			var $category;

			$.ajax({
				type: 'post',
				url: $URL,
				data: {
					$category: $category
				},
			    success: function(data){
				    $("html").html(data);
				    $("*").each(function(){
					    $(this).addClass("kia-animate");
				    })
;			    }
			});
		});
	});
*/

	//
	// ** SVG Triangle points config
	//
	$(function(){
		if( $(".polygon").length > 0 ){
			var $width  = $(window).width() / 3;
			var $height = $(window).width() / 4;

			$("#triangle-top").attr("points", "0," + $height + " 0,0 " + $width + ",0");
			$("#triangle-bottom").attr("points", $width + "," + $height + " 0, " + $height + " " + $width + ",0");

			$(window).resize(function(){
				var $width  = $(window).width() / 3;
				var $height = $(window).width() / 4;

				$("#triangle-top").attr("points", "0," + $height + " 0,0 " + $width + ",0");
				$("#triangle-bottom").attr("points", $width + "," + $height + " 0, " + $height + " " + $width + ",0");
			});
		}
	});

	//
	// ** Determine what category should be shown depending on the URL parameter
	//
	$(function(){
		if( $("body.case-studies").length > 0 ){
			var $example = location;
			var $current = location.origin + location.pathname;
			if( $current == $example ){
				$(".filter-option[data-id='all']").addClass("active");
			}
		}
	});

	//
	// ** Make all products the same height
	//
	$(function(){
		if( $(".ci-product-archive").length > 0 ){
			var $min_height = 0;

			$(".products .product").each(function(){
				if( $(this).outerHeight(true) > $min_height ){
					$min_height = $(this).outerHeight();
				}
			});

			$(".products .product").css("min-height", $min_height);
		}
	});

	//
	// ** Services block initialise panels
	//
	$(function(){
		if( $(".ci-service-panels").length > 0 ){
			var $content = $(".service-content");
			var $thumbnail = $(".service-thumbnail");
			var $height = 0;

			$content.each(function(){
				if( $(this).outerHeight() > $height ){
					$height = $(this).outerHeight();
				}
			});
			$thumbnail.each(function(){
				$(this).css("height", $height);
			});

			// Repeat on resize
			$(window).resize(function(){
				$content.each(function(){
					if( $(this).outerHeight() > $height ){
						$height = $(this).outerHeight();
					}
				});
				$thumbnail.each(function(){
					$(this).css("height", $height);
				});
			});
		}
	});

	//
	// ** Make all archive CTA buttons level at the bottom of the excerpt section
	//
	$(function(){
		var $content = ".product-content, .multi-post-content";
		var $height = 0;

		$.each( $($content), function(){
			if( $(this).outerHeight(true) > $height ){
				$height = $(this).outerHeight();
			}
		});
		$($content).css("height", $height + "px");

		$(window).resize(function(){
			$.each( $($content), function(){
				if( $(this).outerHeight(true) > $height ){
					$height = $(this).outerHeight();
				}
			});
			$($content).css("height", $height + "px");
		});
	});

	// MOBILE --- MOBILE --- MOBILE --- MOBILE --- MOBILE --- MOBILE --- MOBILE --- MOBILE --- MOBILE --- MOBILE --- MOBILE --- MOBILE --- MOBILE --- MOBILE --- MOBILE --- MOBILE --- MOBILE --- MOBILE --- MOBILE --- MOBILE --- MOBILE --- MOBILE --- MOBILE --- MOBILE --- MOBILE --- MOBILE --- MOBILE --- MOBILE --- MOBILE --- MOBILE //

	//
	// ** Change Case Studies category on select change
	//
	$(function(){
		var $cat = $(".mobile-categories select");

		if( $cat.length > 0 ){
			$cat.on("change", function(){
				var $url = $(this).val(); // Store the URL from the value

				if($url){
					window.location = $url; // Redirect to selected URL
				}

				return false;
			});
		}
	});
});